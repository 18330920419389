/* You can add global styles to this file, and also import other style files */
@import 'material-icons/iconfont/material-icons.css';

html { height: -webkit-fill-available; }

body { position: relative; height: -webkit-fill-available; margin: 0; font-family: Roboto Slab,serif !important; background-color: #f8f8f8; }

h1, h2, h3, h4, h5, h6, p, span {
  font-family: Roboto Slab,serif !important;
}

.cdk-overlay-container .cdk-overlay-pane { 
  margin: auto !important;
}

